import React from 'react'
import Section from '../ui/section'
import Container from '../ui/container'
import Typography from '../ui/typography'
import * as styles from './financial_resilience.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const { Paragraph } = Typography

export default (props) => (
  <Section className={styles.section}>
    <Container className={styles.container}>
      <Paragraph className={styles.tag}>{props.data.subtitle}</Paragraph>
      <h2 className={styles.h2} responsive={true}>
        {props.data.title}
      </h2>
      <h3 className={styles.h3} responsive={true}>
        {props.data.subHeading}
      </h3>
      <div className={styles.content}>
        {props.data.itemType.map((f, idx) => (
          <div key={idx} className={styles.liItem}>
            {/* <img className="w-32 mb-4" src={svgs[idx]} /> */}
            <div className={styles.liImg}>
              <GatsbyImage
                alt={f.image.title}
                className={styles.image}
                image={f.image.gatsbyImageData}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <h3 className={styles.liTitle}>{f.title}</h3>
            <Paragraph className={styles.liContent}>{f.subtitle}</Paragraph>
            <p className={styles.liCounter}>0{idx + 1}</p>
          </div>
        ))}
      </div>
    </Container>
  </Section>
)
